import Common from '@/assets/js/common.js'
import Constants from '../../config.local.js'

export default {

	methods: {
		getStallionSettings(horse_id) {
			return this.$storage.db.t('semence_stallion')
			.then(table => {
				return table.where({
					semencestallion_horse: parseInt(horse_id),
				})
			})
			.then(col => {
				return col.toArray()
			})
			.then(res => {
				return res.length > 0 ? res[0] : null
			})
		},

		createStallionSettings(horse_id, couleur_id, stockage_id, prestockage_id) {
			return this.$storage.db.t('semence_stallion')
                .then(table => {
                    return table.add({
                    	semencestallion_id: Common.getNegativeId(),
                        semencestallion_horse: horse_id,
                        semencestallion_couleur: couleur_id,
                        semencestallion_stockage: stockage_id,
                        semencestallion_prestockage: prestockage_id,
                        semencestallion_licencekey: Constants.USER_LICENCE_KEY,
                        semencestallion_valide: 1
                    })
                })
		},

		updateStallionSettings(setting_id, couleur_id, stockage_id, prestockage_id) {
			return this.$storage.db.t('semence_stallion')
                .then(table => {
                    return table.update(parseInt(setting_id), {
                        semencestallion_couleur: couleur_id,
                        semencestallion_stockage: stockage_id,
                        semencestallion_prestockage: prestockage_id
                    })
                })
		},

		addDilueur(label) {
			return this.$storage.db.t('semence_dilueur')
                .then(table => {
                    return table.add({
                    	semencedilueur_id: Common.getNegativeId(),
                        semencedilueur_label: label,
                        semencedilueur_licencekey: Constants.USER_LICENCE_KEY,
                        semencedilueur_valide: 1
                    })
                })
		},

		getAllDilueurs() {
			return this.$storage.db.t('semence_dilueur')
				.then(table => {
					return table.toCollection()
				})
				.then(col => {
				    return col.toArray()
				})
		},

		updateDilueur(dilueur_id, label) {
			return this.$storage.db.t('semence_dilueur')
                .then(table => {
                    return table.update(parseInt(dilueur_id), {
                        semencedilueur_label: label
                    })
                })
		}
	}
}