<template>
	<div class="row">
		<div class="col-12">
			<div class="box">
				<h2>{{ $t('monte.congelation.ajout_dilueur') }}</h2>
				<form @submit.prevent="validForm">
					<div class="form-group">
						<div class="row">
							<div class="col">
								<div class="input-group">
									<input class="form-control" type="text" :placeholder="$t('monte.congelation.label_dilueur')" v-model="semencedilueur_label" id="semencedilueur_label">
								</div>
							</div>
							<div class="col-auto">
								<button type="submit" v-on:click="checkForm" class="btn btn-primary">
								{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus-circle']"/>
							</button>
							</div>
						</div>
					</div>
				</form>
			</div>

			<div class="box" v-if="dilueurs.length > 0">
				<div>
					<h2>{{ $t('monte.congelation.liste_dilueurs') }}</h2>
					<div class="row">
						<div
							class="col-6 align-items-center pt-2 pb-2 border-top d-flex"
							v-for="dilueur in dilueurs"
							:key="dilueur.semencedilueur_id"
						>
							<b-form-input
								class="mr-1"
								v-model="dilueur.semencedilueur_label"
								:readonly="!dilueur.isInEditMode"
							></b-form-input>

							<button @click="interactUpdateDilueur(dilueur)" class="btn btn-secondary ml-auto"><font-awesome-icon :icon="fontUpdateBtn(dilueur)" :spin="dilueur.isWorking" class="ml-1" /></button>
						</div>
				    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"

	export default {
		name: 'Dilueurs',
		mixins: [Congelation],
		data () {
			return {
				semencedilueur_label: '',
				dilueurs: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.dilueurs = await this.getAllDilueurs()
			},

            async validForm() {
            	if(this.semencedilueur_label === '')
            		return false
				
				await this.addDilueur(this.semencedilueur_label)

				this.successToast('toast.info_save_succes')
                this.init_component()
                this.semencedilueur_label = ''
            },

			async interactUpdateDilueur(dilueur) {
				if(dilueur.isInEditMode) {
					dilueur.isWorking = true

					try {
						await this.updateDilueur(dilueur.semencedilueur_id, dilueur.semencedilueur_label)
					} catch {
						this.failureToast()
					}

					dilueur.isWorking = false
				}

				const dilueurs = this.dilueurs
				this.dilueurs = []
				const d = dilueurs.findIndex(dil => dil.semencedilueur_id == dilueur.semencedilueur_id)
				dilueurs[d].isInEditMode = !dilueur.isInEditMode
				this.dilueurs = dilueurs
			},

			fontUpdateBtn(cat) {
				if(cat.isWorking) {
					return ['far', 'spinner']
				}
				return cat.isInEditMode ? ['far', 'save'] : ['fal', 'pencil-alt']
			}
		}
	}

</script>